import {GanttStatic} from 'dhtmlx-gantt';
import {useEffect} from 'react';
import {useLocation} from 'react-router';

import {TasksLocationState} from '../../../types/location';

export const useTaskMentionClickHandler = (gantt: GanttStatic, openTask: (taskId: string) => void) => {
  const location = useLocation<TasksLocationState>();
  useEffect(() => {
    const taskId = location.state?.taskId;

    if (!taskId) return;

    const handleClick = (e: MouseEvent) => {
      const target = e.target as Element;
      const clickedElement = target.closest(`li[data-bycore-task-id="${taskId}"]`);
      if (clickedElement) {
        const activity = gantt.getTask(taskId);
        if (activity) {
          const lastSelectedTaskId = gantt.getLastSelectedTask();
          if (lastSelectedTaskId) {
            gantt.unselectTask(lastSelectedTaskId);
          }
          gantt.selectTask(taskId);
          const {top} = gantt.getTaskPosition(activity, activity.start_date, activity.end_date);
          gantt.scrollTo(null, top);
        }
        openTask(taskId);
      }
    };

    document.addEventListener('click', handleClick);

    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, [gantt, location.state?.taskId, openTask]);
};
