import {GanttStatic} from 'dhtmlx-gantt';
import {FC, memo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useParams} from 'react-router';

import {useFilterContext} from 'modules/Tasks/components/Filters';
import {Icon} from 'shared/components';
import {CtrlBtnOption} from 'shared/components/CoreNewUI';
import {GANTT_PREFERENCES_KEY} from 'shared/constants/common';
import {IconsMap} from 'shared/constants/icons';
import {toTitleCase} from 'shared/helpers/common';
import {useAnalyticsService, useLocalStorage} from 'shared/hooks';
import {TaskColorMode} from 'shared/models/task';

type Props = {
  gantt: GanttStatic;
};

const ColorTasksModeBtn: FC<Props> = ({gantt}: Props) => {
  const {projectId} = useParams<{projectId: string}>();
  const {viewMode} = useFilterContext();
  const {mixpanel} = useAnalyticsService({extraMeta: {projectId, viewMode}});
  const mixpanelEvent = mixpanel.events.tasks.toolbar.colorMode;

  const {t} = useTranslation('gantt');

  const [getValueFromLS, storeValueToLS] = useLocalStorage<TaskColorMode>({
    key: GANTT_PREFERENCES_KEY,
    path: `byProject.${projectId}.colorTasksMode`,
    defaultValue: null,
    enabled: !!projectId,
  });

  const [selected, setSelected] = useState<TaskColorMode>(getValueFromLS() || TaskColorMode.COMPANY);
  const updateValue = (newValue: TaskColorMode) => {
    if (newValue !== selected) {
      mixpanel.track(mixpanelEvent(newValue));
      storeValueToLS(newValue);
      setSelected(newValue);
      gantt.taskColorMode = newValue;
      gantt.dRender();
    }
  };

  return (
    <CtrlBtnOption
      openOnHover
      icon={<Icon name="paint_roller" colorFill />}
      title={t('toolbar.tasksColorMode.title', 'Color all by...')}
      nestedContentPlacement="right"
      previewSelected={toTitleCase(selected)}
      nested={
        <>
          <CtrlBtnOption
            size="s"
            selected={selected === TaskColorMode.COMPANY}
            icon={<Icon name={IconsMap.company} colorFill />}
            title={t('toolbar.tasksColorMode.options.company')}
            onClick={() => updateValue(TaskColorMode.COMPANY)}
          />
          <CtrlBtnOption
            size="s"
            selected={selected === TaskColorMode.STATUS}
            icon={<Icon name={IconsMap.status_new} colorFill />}
            title={t('toolbar.tasksColorMode.options.status')}
            onClick={() => updateValue(TaskColorMode.STATUS)}
          />
          <CtrlBtnOption
            size="s"
            selected={selected === TaskColorMode.LABOR}
            icon={<Icon name={IconsMap.worker} colorFill />}
            title={t('toolbar.tasksColorMode.options.labor')}
            onClick={() => updateValue(TaskColorMode.LABOR)}
          />
        </>
      }
    />
  );
};
export default memo(ColorTasksModeBtn);
