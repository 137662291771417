import equal from 'fast-deep-equal';
import {createContext, ReactNode, useCallback, useContext, useEffect, useMemo, useRef} from 'react';
import {generatePath, useHistory, useRouteMatch} from 'react-router';

import {getDefaultSchemaValues, TASK_FILTER_PARAMS_SCHEMA} from 'modules/Tasks/components/Filters/utils/constants';
import {getInitialValues} from 'modules/Tasks/components/Filters/utils/functions';
import {useViewMode} from 'modules/Tasks/hooks/useViewMode';
import {TasksLocationState} from 'modules/Tasks/types/location';
import {TasksViewMode} from 'shared/constants/common';
import {useLocalizedRoutes} from 'shared/constants/routes';
import {prepareQueryString} from 'shared/helpers/queryParams';
import {useExactMatchParsedQuery} from 'shared/hooks';
import {usePrevious} from 'shared/hooks/core';
import {TaskFilterQuery, TaskStates} from 'shared/models/task';

import {AvailableTaskFilterStates, FilterContextType, FiltersResetConfig, FiltersState} from './utils';

const FilterContext = createContext<FilterContextType>(null);

export function useFilterContext() {
  return useContext(FilterContext);
}

const cache: {projectId?: string; state?: FiltersState} = {
  projectId: undefined,
  state: null,
};

type FilterProviderProps = {children: ReactNode; projectId: string};

const defaultSchemaValues = getDefaultSchemaValues(TASK_FILTER_PARAMS_SCHEMA);
const defaultQueryParams = getInitialValues();

const FilterProvider = ({children, projectId}: FilterProviderProps) => {
  const [viewMode] = useViewMode(projectId);
  const history = useHistory<{switch?: boolean} & Pick<TasksLocationState, 'eventId' | 'taskId'>>();
  const routes = useLocalizedRoutes();
  const isTaskPage = useRouteMatch(routes.task); // DO we still need this?
  const lastViewMode = usePrevious(viewMode);
  const {params: queryParams} = useExactMatchParsedQuery<TaskFilterQuery>({
    defaultParams: defaultQueryParams[viewMode][TaskStates.active],
    schema: TASK_FILTER_PARAMS_SCHEMA,
    path: routes.tasks,
  });
  const taskState = queryParams?.state ?? TaskStates.active;
  const lastTaskState = usePrevious(queryParams.state);
  const viewModeChanged = viewMode !== lastViewMode;
  const stateChanged = taskState !== lastTaskState;
  const filtersState = useRef<FiltersState>(cache?.state);
  if (cache.projectId !== projectId) {
    cache.projectId = projectId;
    filtersState.current = cache.state = getInitialValues();
  }
  const isFilterQueryChanged = useMemo(() => {
    return !equal(filtersState.current[viewMode][taskState], queryParams);
  }, [queryParams, viewMode, taskState]);

  const reset = ({exclude, newState, fallbackMode = 'initial'}: FiltersResetConfig) => {
    const initialValues = getInitialValues()[viewMode];
    const currentFilterState = {...filtersState.current[viewMode][taskState]};
    const keysToReset = Object.keys(newState || currentFilterState) as (keyof TaskFilterQuery)[];
    keysToReset.forEach((filterKey) => {
      if (!exclude || !exclude?.includes(filterKey)) {
        const value = (newState || currentFilterState)[filterKey];
        const useFallbackValue = !newState || value === null;
        if (useFallbackValue) {
          const fallbackValue =
            fallbackMode === 'initial' ? initialValues[taskState][filterKey] : defaultSchemaValues[filterKey];
          currentFilterState[filterKey] = fallbackValue;
        } else {
          currentFilterState[filterKey] = value;
        }
      }
    });
    updateSearchParams(currentFilterState, true);
  };

  function navigateWithParams(params: TaskFilterQuery) {
    const state = {...history.location.state};
    delete state.switch;
    history.push({
      pathname: generatePath(routes.tasks, {projectId}),
      search: prepareQueryString({values: params}),
      state,
    });
  }

  const updateSearchParams = useCallback(
    (newValues: TaskFilterQuery, force = false) => {
      if ((!isTaskPage && !equal(newValues, filtersState.current[viewMode][taskState])) || force) {
        navigateWithParams(newValues);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isTaskPage, projectId, taskState, viewMode],
  );

  const updateViewMode = useCallback(
    (viewMode: TasksViewMode, state = TaskStates.active) => {
      navigateWithParams(filtersState.current[viewMode][state]);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [routes],
  );

  const onSelectState = (selectedState: AvailableTaskFilterStates) => {
    const nextValues = filtersState.current[viewMode][selectedState];
    updateSearchParams(nextValues);
  };

  // for navigation inside application, to prevent apply parsed params
  // for example to navigate to lookahead view from different page with keeping cached params
  if (!history.location.state?.switch && isFilterQueryChanged) {
    filtersState.current[viewMode][taskState] = queryParams;
  }

  useEffect(() => {
    updateViewMode(viewMode, taskState);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewModeChanged, stateChanged]);

  return (
    <FilterContext.Provider
      value={{
        queryParams: filtersState.current[viewMode][taskState],
        filtersState,
        isFilterQueryChanged,
        reset,
        updateSearchParams,
        onSelectState,
        taskState,
        viewMode,
        updateViewMode,
      }}
    >
      {children}
    </FilterContext.Provider>
  );
};

export default FilterProvider;
