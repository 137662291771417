import * as Sentry from '@sentry/react';
import cn from 'classnames';
import {useCallback, useState, MouseEvent} from 'react';
import {useTranslation} from 'react-i18next';
import {toast} from 'react-toastify';

import GalleryEmpty from 'modules/Tasks/components/SidebarPanel/components/InfoGallery/components/GalleryEmpty/GalleryEmpty';
import {Button, Icon, SkeletonPreloader, Tooltip} from 'shared/components';
import {IconsMap} from 'shared/constants/icons';

import s from './ImageGallery.module.scss';

type Attachment = {
  url: string;
};

type ImageGalleryProps = {
  attachments: Attachment[];
  displayGalleryCopy?: boolean;
  handleExpanded?: (expanded: boolean) => void;
  isLoading?: boolean;
  isMention?: boolean;
};

export function ImageGallery({
  attachments,
  displayGalleryCopy,
  handleExpanded,
  isLoading,
  isMention,
}: ImageGalleryProps) {
  const {t} = useTranslation(['common', 'gantt', 'task']);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [isExpanded, setIsExpanded] = useState(false);
  const image =
    isMention && attachments?.length > 0
      ? `${attachments[currentImageIndex]?.url.replace('download', 'thumbnail')}?width=300&height=300&crop=true`
      : attachments?.[currentImageIndex]?.url ?? '';

  const handleImageClick = useCallback(
    (e: MouseEvent<HTMLDivElement>) => {
      e.stopPropagation();
      setIsExpanded((prev) => !prev);
      if (handleExpanded) {
        handleExpanded(!isExpanded);
      }
    },
    [handleExpanded, isExpanded],
  );

  const cycleImage = useCallback(
    (direction: 'left' | 'right', e: MouseEvent) => {
      e.stopPropagation();
      setCurrentImageIndex((prevIndex) => {
        const newIndex =
          direction === 'left'
            ? (prevIndex - 1 + attachments.length) % attachments.length
            : (prevIndex + 1) % attachments.length;
        return newIndex;
      });
    },
    [attachments?.length],
  );

  const openInNewWindow = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    window.open(attachments[currentImageIndex].url, '_blank');
  };

  const copyImageUrl = (e: MouseEvent<HTMLButtonElement> | TouchEvent) => {
    e.stopPropagation();
    const {url} = attachments[currentImageIndex];
    if (url) {
      navigator.clipboard
        .writeText(url)
        .then(() => toast.info(t('gantt:toast.success.copy', 'Copied!')))
        .catch((err) => {
          Sentry.captureException(err);
          toast.error(t('gantt:toast.error.copy', 'Something went wrong while copying the link.'));
        });
    }
  };

  if (isLoading) {
    return (
      <div className={s['image-loading']}>
        <SkeletonPreloader when className={s['image-container']} height="100%" />
      </div>
    );
  }

  if (!attachments?.length) {
    if (displayGalleryCopy) {
      return (
        <>
          <p>{t('task:task_form.gallery.label', 'Gallery')}</p>
          <GalleryEmpty className={s['image-empty']} />
        </>
      );
    }
    return null;
  }

  return (
    <>
      {displayGalleryCopy ? <p>{t('task:task_form.gallery.label', 'Gallery')}</p> : null}
      <div
        className={cn(s['image-container'], {
          [s['image-container-expanded']]: isExpanded,
        })}
        onClick={handleImageClick}
      >
        <img className={s['image-content']} src={image} alt="" />
        {attachments.length > 1 && !isExpanded ? (
          <div className={s['image-overlay']}>+{attachments.length - 1}</div>
        ) : null}
        {isExpanded && attachments.length > 1 ? (
          <div className={s['image-navigation']}>
            <Button
              iconOnly
              icon={<Icon className={s['image-navigation-icon']} name={IconsMap.arrow_2_left} size={30} />}
              onClick={(e) => cycleImage('left', e)}
            />
            <span>
              {currentImageIndex + 1} / {attachments.length}
            </span>
            <Button
              iconOnly
              icon={<Icon className={s['image-navigation-icon']} name={IconsMap.arrow_2_right} size={30} />}
              onClick={(e) => cycleImage('right', e)}
            />
          </div>
        ) : null}
        <div className={cn(s['image-controls'], {[s.hide]: !isExpanded})} onClick={(e) => e.stopPropagation()}>
          <Tooltip text={t('common:image_gallery.tooltip.copy_link')} placement="bottom">
            <Button
              iconOnly
              icon={
                <Icon className={s['image-navigation-icon']} name={IconsMap.copy} onClick={copyImageUrl} size={20} />
              }
            />
          </Tooltip>
          <Tooltip text={t('common:image_gallery.tooltip.open_image')} placement="bottom">
            <Button
              iconOnly
              icon={
                <Icon
                  className={s['image-navigation-icon']}
                  name={IconsMap['new-tab']}
                  onClick={openInNewWindow}
                  size={20}
                />
              }
            />
          </Tooltip>
        </div>
      </div>
    </>
  );
}
