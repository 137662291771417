/* eslint-disable @typescript-eslint/naming-convention */
import cn from 'classnames';
import dayjs from 'dayjs';
import {GanttStatic} from 'dhtmlx-gantt';

import {GanttTask} from 'modules/Tasks/components/Gantt/types';
import {
  GanttZoomLevels,
  getBaselineDateRange,
  getVisibleDateRange,
  isPlaceholderTask,
} from 'modules/Tasks/components/Gantt/utils';
import {getTaskDateRange} from 'modules/Tasks/components/Gantt/utils/gantt';
import {calculateRowHeight, getTaskColor, getTaskOpenIssuesIds} from 'modules/Tasks/utils/functions';
import {getQuarterOfYear, safeFormatDate, startOf} from 'shared/helpers/dates';
import {TaskObjectType} from 'shared/models/task';

const COLOR_MAX_NESTED_LEVEL = 3;

export const getWBSColor = (task: GanttTask) => {
  const level = task.$level > COLOR_MAX_NESTED_LEVEL ? COLOR_MAX_NESTED_LEVEL : task.$level;
  return task.object_type === TaskObjectType.summary ? 'wbs_level__' + level : '';
};

export function getTimelineBoundaryDates(gantt: GanttStatic) {
  const {start_date, end_date} = gantt.getSubtaskDates();
  const unit = gantt.getState().scale_unit;

  const startDate = new Date(gantt.config.start_date || gantt.calculateEndDate({start_date, duration: -1, unit}));
  const endDate = new Date(
    gantt.calculateEndDate({
      start_date: gantt.config.end_date || end_date,
      duration: gantt.config.end_date ? -1 : 1,
      unit,
    }),
  );
  return {startDate, endDate};
}

function getTimelineDaysByMonths(gantt: GanttStatic): Record<string, {day: number; isWeekend: boolean; date: Date}[]> {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const {startDate, endDate} = getTimelineBoundaryDates(gantt);
  const projectId = gantt.getTaskBy((task) => !isPlaceholderTask(gantt, task))?.[0]?.projectId;
  const calendar = gantt.getCalendar(projectId);
  const daysByMonths = {};
  while (startDate <= endDate) {
    const month = safeFormatDate(startDate, 'MMMM, YYYY');
    if (!daysByMonths[month]) {
      daysByMonths[month] = [];
    }
    daysByMonths[month].push({
      day: startDate.getDate(),
      isWeekend: calendar ? !calendar.isWorkTime(startDate) : !gantt.isWorkTime(startDate),
      date: new Date(startDate),
    });
    startDate.setDate(startDate.getDate() + 1);
  }
  return daysByMonths;
}

function getDayCell(gantt: GanttStatic) {
  return gantt.$task_scale?.querySelector<HTMLTableCellElement>(
    '.gantt_scale_line:last-child .gantt_scale_cell:not(:last-child):not(:first-child)',
  );
}

function getDaysHeaders(gantt: GanttStatic): string {
  const daysByMonths = getTimelineDaysByMonths(gantt);
  const cellWidth = getDayCell(gantt)?.offsetWidth || 0;
  return Object.keys(daysByMonths)
    .map((month) => {
      return daysByMonths[month]
        .map(({date, isWeekend}) => {
          return `<th style="max-width: ${cellWidth}px;min-width: ${cellWidth}px" class="print-table__cell print-table__cell--th print-table__cell--day ${
            isWeekend ? 'weekend' : ''
          }">${dayjs(date).format('D, dd')}</th>`;
        })
        .join('');
    })
    .join('');
}

// for zoom level = day
function getMonthsDaysHeaders(gantt: GanttStatic): string {
  const cellWidth = getDayCell(gantt)?.offsetWidth || 0;
  const daysByMonths = getTimelineDaysByMonths(gantt);
  return Object.keys(daysByMonths)
    .map((month) => {
      return `<th class="print-table__cell print-table__cell--th print-table__cell--timeline-group" style="max-width: ${cellWidth}px" colspan="${daysByMonths[month].length}">${month}</th>`;
    })
    .join('');
}

function getTimelineWeeksByMonths(gantt: GanttStatic) {
  const {startDate, endDate} = getTimelineBoundaryDates(gantt);
  let startWeek = dayjs(startDate).weekday(6);
  const endWeek = dayjs(endDate).weekday(6);
  const result = {};
  while (endWeek.diff(startWeek, 'day') >= 0) {
    const year = startWeek.format('YYYY');
    const month = startWeek.format('M');
    if (!result?.[year]?.[month]) {
      result[year] = {...result[year], [month]: []};
    }
    result[year][month].push(startWeek.toDate());
    startWeek = startWeek.add(1, 'week');
  }
  return result;
}

function getTimelineMonthsByYears(gantt: GanttStatic) {
  const {startDate, endDate} = getTimelineBoundaryDates(gantt);
  let parsedStartDate = startOf(startDate, 'month');
  const parsedEndDate = startOf(endDate, 'month');
  const result = {};
  while (parsedEndDate.diff(parsedStartDate, 'month') >= 0) {
    const year = parsedStartDate.format('YYYY');
    if (!result?.[year]) {
      result[year] = [];
    }
    result[year].push(parsedStartDate.startOf('month').toDate());
    parsedStartDate = parsedStartDate.add(1, 'month');
  }
  return result;
}

function getDividedMonthsInQuarters(gantt: GanttStatic): Record<number, unknown> {
  const monthsByYears = getTimelineMonthsByYears(gantt);
  return Object.keys(monthsByYears).reduce((acc, year) => {
    acc[year] = monthsByYears[year].reduce((res, monthDate) => {
      const quarter = getQuarterOfYear(monthDate);
      const label = `Q${quarter}`;
      if (!res[label]) {
        res[label] = [];
      }
      res[label].push(monthDate);
      return res;
    }, {});
    return acc;
  }, {});
}

function getQuartersHeaders(gantt: GanttStatic): string {
  const dividedByQuarters = getDividedMonthsInQuarters(gantt);
  const cellWidth = getDayCell(gantt)?.offsetWidth || 0;
  return Object.keys(dividedByQuarters)
    .map((year) => {
      return Object.keys(dividedByQuarters[year])
        .map((quarter) => {
          return `<th class="print-table__cell print-table__cell--th" style="max-width:${cellWidth}px" colspan="${dividedByQuarters[year][quarter].length}">${quarter}, ${year}</th>`;
        })
        .join('');
    })
    .join('');
}
function getWeekMonthsHeaders(gantt: GanttStatic): string {
  const timeRange = getTimelineWeeksByMonths(gantt);
  const cellWidth = getDayCell(gantt)?.offsetWidth || 0;
  return Object.keys(timeRange)
    .map((year) => {
      return Object.keys(timeRange[year])
        .map((month) => {
          return `<th class="print-table__cell print-table__cell--th" style="max-width:${cellWidth}px" colspan="${
            timeRange[year][month].length
          }">${dayjs(timeRange[year][month][0]).format('MMMM, YYYY')}</th>`;
        })
        .join('');
    })
    .join('');
}

function getWeekDaysHeaders(gantt: GanttStatic) {
  const timeRange = getTimelineWeeksByMonths(gantt);
  const cellWidth = getDayCell(gantt)?.offsetWidth || 0;
  return Object.keys(timeRange)
    .map((year) => {
      return Object.keys(timeRange[year])
        .map((month) => {
          const days = timeRange[year][month];
          return days
            .map((day) => {
              return `<th style="max-width: ${cellWidth}px;min-width: ${cellWidth}px" class="print-table__cell print-table__cell--th print-table__cell--day">${dayjs(
                day,
              ).format('D')}</th>`;
            })
            .join('');
        })
        .join('');
    })
    .join('');
}

export function generateWeekendLine(gantt: GanttStatic): string {
  const daysByMonths = getTimelineDaysByMonths(gantt);
  const days = Object.values(daysByMonths).flat(1);
  const cellWidth = getDayCell(gantt)?.offsetWidth;
  const weekendBoxes = days.map(({isWeekend}, index) => {
    const borderWidth = 1;
    if (isWeekend) {
      const nextIsWeekend = days[index + 1]?.isWeekend;
      const left = index * cellWidth - (nextIsWeekend ? borderWidth : 0);
      const width = cellWidth + (nextIsWeekend ? borderWidth : 0);
      return `<div class="weekend" style="height: 100%; position: absolute; width: ${width}px; left: ${left}px"></div>`;
    }
  });
  return `<div class="print_table__line--weekend">${weekendBoxes.join('')}</div>`;
}

export function getTasks(gantt: GanttStatic): GanttTask[] {
  const tasks = gantt.getTaskByTime();
  const filteredTasks = tasks.filter(({type}) => type !== 'placeholder');

  // when we call gantt.getTaskByTime()
  // the child tasks are located in the end of the list
  return filteredTasks.sort((a, b) => {
    if (a.$index > b.$index) return 1;
    if (a.$index < b.$index) return -1;
    return 0;
  });
}

const generateBaselineEl = ({gantt, task}) => {
  const sizes = gantt.getTaskPosition(task, ...getVisibleDateRange(gantt, getBaselineDateRange(task)));
  const height = calculateRowHeight(sizes.height);
  const top = (Math.ceil(sizes.height) - Math.ceil(height)) / 2;
  const containerStyles = `left: ${sizes.left}px; top: ${top + height + 7}px; height: ${height}px; width: ${
    sizes.width
  }px;`;
  return `<div class="gantt_task_line gantt_bar_task gantt_task_inline_color baseline-task ${gantt.templates.task_class(
    task.baseline_start,
    task.baseline_end,
    task,
  )}" style="${containerStyles}">
       <div></div>
    </div>`;
};
export function generateTaskLineForGanttView(gantt: GanttStatic, task: GanttTask, isBaseline: boolean): string {
  const sizes = gantt.getTaskPosition(task, ...getVisibleDateRange(gantt, getTaskDateRange(task)));
  const isWBS = task.object_type === TaskObjectType.summary;
  const height = isWBS ? 15 : calculateRowHeight(sizes.height);
  const top = (Math.ceil(sizes.height) - Math.ceil(height)) / 2;
  const containerStyles = `left: ${sizes.left}px; top: ${
    isBaseline ? top + 3 : top + 2
  }px; height: ${height}px; width: ${sizes.width}px; ${isBaseline ? 'font-size: 12px;' : ''}`;

  if (isWBS) {
    return `<div class="wbs_container ${isBaseline ? 'wbs_container--baseline' : ''}" style="${
      containerStyles + 'z-index: 1005;'
    }"></div>${isBaseline ? generateBaselineEl({gantt, task}) : ''}`;
  }

  const openedIssues = getTaskOpenIssuesIds(task?.status_issue_task_ids_pairs);
  const badge = openedIssues.length
    ? `<span class="gantt__badge ${cn({
        gantt__badge_offset: !!task.sourceDeps?.length && !isBaseline,
        gantt__badge_baselineMode: isBaseline,
      })}">${openedIssues.length}</span>`
    : '';

  return `<div class="gantt_task_line gantt_bar_task gantt_task_inline_color ${gantt.templates.task_class(
    task.start_date,
    task.end_date,
    task,
  )}" style="${containerStyles + `background-color: ${getTaskColor(gantt, task)}; z-index: 1005;`}">
        <div class="gantt_task_progress_wrapper">
        <div class="gantt_task_progress" style="width: 0%"></div>
        <div class="gantt_task_content" style="line-height: ${height}px">${task?.abbrev || task.name}</div>
        ${badge}
      </div>
    </div>
    ${task.sourceDeps?.length ? generateTaskLinks(gantt, task, isBaseline) : ''}
    ${isBaseline ? generateBaselineEl({gantt, task}) : ''}
`;
}

const generateMilestoneBaselineEl = (gantt: GanttStatic, task: GanttTask) => {
  const {height, width, ...sizes} = gantt.getTaskPosition(
    task,
    ...getVisibleDateRange(gantt, getBaselineDateRange(task)),
  );
  const leftOffsetEnd = sizes.left + sizes.rowHeight - 3;
  const left = task.object_subtype === 'start' ? sizes.left - width / 2 : leftOffsetEnd;
  const containerStyles = `left: ${left}px; top: 2px; height: ${height}px; width: ${height}px; margin-top: ${
    height + 5
  }px; border: none; background-color: #FFE3B3; z-index: 1002;`;
  return `
    <div class="gantt_task_line gantt_task_line__milestone-type gantt_milestone gantt_bar_milestone ${gantt.templates.task_class(
      task.baseline_end,
      task.baseline_start,
      task,
    )}" style="${containerStyles}">
          <div class="gantt_task_content"></div>
      </div>
  `;
};

export function generateMilestoneForGanttView(gantt: GanttStatic, task: GanttTask, isBaseline?: boolean): string {
  const sizes = gantt.getTaskPosition(task, ...getVisibleDateRange(gantt, getTaskDateRange(task)));
  const height = sizes.rowHeight;
  const width = Math.sqrt((height / 2) * (height / 2) * 2) * 0.94;
  const leftOffsetEnd = isBaseline ? sizes.left + sizes.rowHeight - 3 : sizes.left + width + 3;
  const left = task.object_subtype === 'start' ? sizes.left - width / 2 : leftOffsetEnd;
  const containerStyles = `left: ${left}px; top: ${isBaseline ? 2 : 6}px; height: ${
    isBaseline ? sizes.height : width
  }px; width: ${isBaseline ? sizes.height : width}px; z-index: 1002;`;
  return `<div class="gantt_task_line gantt_task_line__milestone-type gantt_milestone gantt_bar_milestone ${gantt.templates.task_class(
    task.start_date,
    task.end_date,
    task,
  )}" style="${containerStyles}">
        <div class="gantt_task_content"></div>
    </div>
    ${task.sourceDeps?.length ? generateTaskLinks(gantt, task, isBaseline) : ''}
    ${isBaseline ? generateMilestoneBaselineEl(gantt, task) : ''}
`;
}

function generateTaskLinks(gantt: GanttStatic, task: GanttTask, isBaseline?: boolean): string {
  return task.sourceDeps?.reduce((template, link) => {
    const selector = `.gantt_task_link[data-link-id="${link.id}"]`;
    const linkContainer = document.querySelector(selector) as HTMLDivElement;
    if (linkContainer) {
      const cloned = linkContainer.cloneNode(true) as HTMLDivElement;
      const topOffset = isBaseline ? 10 : 0;
      cloned.style.left = '0px';
      cloned.style.position = 'absolute';
      cloned.style.top = `-${task.$index * gantt.config.row_height + topOffset}px`;
      cloned.style.zIndex = '1000';
      template += cloned.outerHTML;
    }
    return template;
  }, '');
}

// for zoom level = month
function getMonthsYearsHeaders(gantt: GanttStatic): string {
  const monthsByYears = getTimelineMonthsByYears(gantt);
  const cellWidth = getDayCell(gantt)?.offsetWidth || 0;
  return Object.keys(monthsByYears)
    .map((year) => {
      return `<th class="print-table__cell print-table__cell--th" style="max-width:${cellWidth}px" colspan="${monthsByYears[year].length}">${year}</th>`;
    })
    .join('');
}

function getMonthsHeaders(gantt: GanttStatic): string {
  const monthsByYears = getTimelineMonthsByYears(gantt);
  const cellWidth = getDayCell(gantt)?.offsetWidth || 0;
  return Object.keys(monthsByYears)
    .map((year) => {
      return monthsByYears[year]
        .map((date) => {
          return `<th style="max-width: ${cellWidth}px;min-width: ${cellWidth}px" class="print-table__cell print-table__cell--th print-table__cell--day">${safeFormatDate(
            date,
            'MMM',
          )}</th>`;
        })
        .join('');
    })
    .join('');
}

function getYearHeaders(gantt: GanttStatic): string {
  const dividedQuarterByYear = getDividedMonthsInQuarters(gantt);
  const cellWidth = getDayCell(gantt)?.offsetWidth || 0;
  return Object.keys(dividedQuarterByYear)
    .map((year) => {
      return `<th class="print-table__cell print-table__cell--th" style="max-width:${cellWidth}px" colspan="${
        Object.keys(dividedQuarterByYear[year]).length
      }">${year}</th>`;
    })
    .join('');
}

function getYearsQuartersHeaders(gantt: GanttStatic): string {
  const dividedQuarterByYear = getDividedMonthsInQuarters(gantt);
  const cellWidth = getDayCell(gantt)?.offsetWidth || 0;
  return Object.keys(dividedQuarterByYear)
    .map((year) => {
      return Object.keys(dividedQuarterByYear[year])
        .map((quarter) => {
          return `<th style="max-width: ${cellWidth}px;min-width: ${cellWidth}px" class="print-table__cell print-table__cell--th print-table__cell--day">${quarter}</th>`;
        })
        .join('');
    })
    .join('');
}

export function getTimescaleTopLevelHeaders(gantt: GanttStatic, ganttZoomLevel: GanttZoomLevels): string {
  switch (ganttZoomLevel) {
    case GanttZoomLevels.DAY:
      return getMonthsDaysHeaders(gantt);
    case GanttZoomLevels.WEEK:
      return getWeekMonthsHeaders(gantt);
    case GanttZoomLevels.MONTH:
      return getMonthsYearsHeaders(gantt);
    case GanttZoomLevels.QUARTER:
      return getQuartersHeaders(gantt);
    case GanttZoomLevels.YEAR:
      return getYearHeaders(gantt);
    default:
      return getMonthsDaysHeaders(gantt);
  }
}

export function getTimescaleBotLevelHeaders(gantt: GanttStatic, ganttZoomLevel: GanttZoomLevels) {
  switch (ganttZoomLevel) {
    case GanttZoomLevels.DAY:
      return getDaysHeaders(gantt);
    case GanttZoomLevels.WEEK:
      return getWeekDaysHeaders(gantt);
    case GanttZoomLevels.MONTH:
      return getMonthsHeaders(gantt);
    case GanttZoomLevels.QUARTER:
      return getMonthsHeaders(gantt);
    case GanttZoomLevels.YEAR:
      return getYearsQuartersHeaders(gantt);
    default:
      return getDaysHeaders(gantt);
  }
}

export function excludeCollapsedTasks(gantt: GanttStatic, task: GanttTask): boolean {
  if (task.parent && task.parent !== gantt.config.root_id && gantt.isTaskExists(task.parent)) {
    const parentTask = gantt.getTask(task.parent);
    return parentTask.$open;
  }
  return true;
}
