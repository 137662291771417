/* eslint-disable @typescript-eslint/naming-convention */
import {Activity, FeedbackRollup, TaskAssigneesGantt} from 'modules/Tasks/components/Gantt/types';
import {taskDtoDateFieldsRaw} from 'shared/constants/task';
import {CompanyOrgs} from 'shared/models/company';
import {TaskObjectSubType, TaskObjectType} from 'shared/models/task/const';
import {TaskAssignees, TaskGroup} from 'shared/models/task/member';
import {TaskStatus} from 'shared/models/task/taskStatus';

import {DailyType} from '../feedback';
import {ProjectCustomFieldDef} from '../project';

type TaskDateFieldsRaw = (typeof taskDtoDateFieldsRaw)[number];

export type TaskDateProperties = keyof TaskDates;

type TaskCustomField = Pick<ProjectCustomFieldDef, 'internalFieldName'> & {value: string};

export type GanttTaskCustomField = {internal_field_name: string; value: string};

type TaskDates = {
  actualStart?: string;
  actualEnd?: string;
  actualEndDate?: string;
  actualStartDate?: string;
  startDate: string;
  endDate: string;
  schedEndDate: string;
  schedStartDate: string;
};

export type TaskPerDateOverrideColor = {
  [key: string]: {
    abbrev: string;
    lookahead_color: string;
    issue_day_type?: string;
    issue_ids?: string[];
  };
};

export type IssueStatusTaskIDsPair = {
  issue_task_ids: string[];
  issue_task_status: TaskStatus;
};

export type IssueModelDTO = {
  activities?: Activity[];
  costDays?: number;
  costTrackingNumber?: string;
  culpableOrgId?: string;
  culpableOrg?: CompanyOrgs;
  impact: TaskIssueImpact;
  issueType: string;
  taskIds: string[];
} & TaskModelDTO;

type TaskRiskModelDTO = {
  labels: {
    label: string;
    source: string;
    isDeleted: boolean;
    timeUpdated: string;
  }[];
};

type ChatEvent = {
  eventFormattedBody: string;
  eventId: string;
  eventMediaUrls: string[];
  groupId: string;
};

export type TaskModelDTO = {
  abbrev?: string;
  assignmentCount: number;
  calendarDays: boolean;
  completionAmount: string;
  completionTarget: string;
  completionUnit: TaskCompletionUnits;
  createdBy: string;
  createdUsing: TaskCreationSource;
  dateList: string[];
  description: string;
  duration: number;
  estLaborHours: number;
  extChatEvents?: ChatEvent[];
  id: string;
  issueTaskIds: string[];
  statusIssueTaskIdsPairs?: IssueStatusTaskIDsPair[];
  keywords: string;
  location: string;
  lookaheadColor: string;
  name: string;
  objectType: TaskObjectType;
  objectSubtype: TaskObjectSubType;
  outlineIsLeaf: boolean;
  outlineSortKey: string;
  parentTask?: TaskDetailsModelDTO;
  projectId: string;
  punchlist: PunchList;
  responsibleOrgId?: string;
  responsibleOrg: CompanyOrgs;
  responsible: TaskAssignees[];
  status: TaskStatus;
  statusHistory: TaskHistoryType[];
  timeCreated: string;
  timeUpdated: string;
  risk?: TaskRiskModelDTO;
  timeRemoved: string;
  type?: string;
  uniqueId?: string;
  projectedLabor: string;
  actualLabor: string;
  csiCode: string;
  phaseCode: string;
  costCode: string;
  customCode: string;
  perDateOverride?: TaskPerDateOverrideColor;
  customFields?: TaskCustomField[];
  feedbackByDate?: FeedbackByDateTask[];
  feedbackRollup?: FeedbackRollupDetails;
} & TaskDates;

interface FeedbackRollupDetails {
  averageLabor: string;
  completionAmount: string;
}

export type FeedbackByDate = {
  date: string;
  workerId: string;
  value: number;
  timeCreated: string;
};

export type FeedbackByDateTask = {
  feedbackByDate: FeedbackByDate[];
  field: DailyType;
};

export type FeedbackByDateTaskRaw = {
  feedback_by_date: FeedbackByDate[];
  field: DailyType;
};

export type TaskRiskModelRawDTO = {
  labels: {
    label: string;
    source: string;
    is_deleted: boolean;
    time_updated: string;
  }[];
};

export type TaskModelRawDTO = {
  assignment_count: number;
  completion_amount: string;
  completion_target: string;
  completion_unit: TaskCompletionUnits;
  created_by: string;
  created_using: string;
  date_list: string[];
  description: string;
  duration: number;
  est_labor_hours: number;
  id: string;
  keywords: string;
  location: string;
  name: string;
  object_type: TaskObjectType;
  object_subtype: TaskObjectSubType;
  outline_is_leaf: boolean;
  outline_sort_key: string;
  project_id: string;
  punchlist: PunchList;
  responsible_org_id?: string;
  responsible_org: GanttCompanyOrgs;
  status: TaskStatus;
  status_history: TaskHistoryRawType[];
  issue_task_ids: string[];
  status_issue_task_ids_pairs: IssueStatusTaskIDsPair[];
  risk?: TaskRiskModelRawDTO;
  task_ids?: string[];
  time_created: string;
  time_updated: string;
  time_removed?: string;
  type?: string;
  unique_id?: string;
  responsible?: TaskAssigneesGantt[];
  lookahead_color?: string;
  abbrev?: string;
  projected_labor: string;
  estimated_labor: string;
  progress: string;
  actual_labor: string;
  csi_code: string;
  phase_code: string;
  cost_code: string;
  custom_code: string;
  per_date_override?: TaskPerDateOverrideColor;
  calendar_days: boolean;
  custom_fields?: GanttTaskCustomField[];
  feedback_by_date: FeedbackByDateTaskRaw[];
  feedback_rollup: FeedbackRollup;
  comment_count?: number | string;
} & TaskGanttModelDTODates;

export type IssueModelRawDTO = {
  cost_days: number;
  cost_impact: boolean;
  cost_tracking_number: string;
  culpable_org: GanttCompanyOrgs;
  culpable_org_id: string;
  impact: string;
  involved_org_ids: string[];
  issue_type: string;
  task_ids: string[];
} & TaskModelRawDTO;

type ChatEventMessageType =
  | `com.bycore.task.${TaskStatus}`
  | 'm.room.alias'
  | 'm.room.create'
  | 'm.room.member'
  | 'm.room.message.file'
  | 'm.room.message.image'
  | 'm.room.message.text'
  | 'm.room.message'
  | 'm.room.name'
  | 'm.room.invite'
  | 'm.reaction'
  | 'm.room.redaction'
  | `com.bycore.task.${TaskStatus.assigned}.admin`
  | `com.bycore.task.${TaskStatus.assigned}.worker`
  | 'com.bycore.task.mention'
  | 'com.bycore.task.punchlist_changed'
  | 'unsupported';

interface ExtChatEvent {
  age: number;
  content: ExtChatEventContent;
  eventId: string;
  originServerTs: number;
  roomId: string;
  sender: string;
  stateKey: string;
  type: string;
  unsigned: Record<string, string>;
  userId: string;
}

export interface ExtChatEventMentionEvent {
  content: ExtChatEventMentionContent;
  eventId: string;
  roomId: string;
  sender: string;
  type: Extract<ChatEventMessageType, 'com.bycore.task.mention'>;
  unsigned: Record<string, number>;
}

export interface ExtChatEventMention {
  event: ExtChatEventMentionEvent;
  eventId: string;
  groupId: string;
}

interface ExtChatEventContent {
  attachments: Attachment[];
  body: string;
  displayname: string;
  formattedBody: string;
  info: ImageInfo;
  mRelatesTo: Record<string, string>;
  msgtype: string;
  replyAttachments: string[];
  stateKey: string;
  taskId: string;
  url: string;
}

interface ExtChatEventMentionWbsChain {
  taskId: string;
  name: string;
}

export interface ExtChatEventMentionActivites {
  taskId: string;
  name: string;
  wbsChain: ExtChatEventMentionWbsChain[];
  status: TaskStatus;
}

export interface ExtChatEventMentionIssues {
  issueId: string;
  name: string;
  status: TaskStatus;
}

export interface ExtChatEventMentionContent {
  activities: ExtChatEventMentionActivites[];
  attachments: Attachment[];
  body: string;
  formattedBody: string;
  issues: ExtChatEventMentionIssues[];
  mentionIsNotPublic: boolean;
  msgtype: 'm.text';
}

export interface Attachment {
  info: ImageInfo;
  msgtype: string;
  url: string;
}

interface ImageInfo {
  h: number;
  mimetype: string;
  size: number;
  w: number;
}

interface MImage {
  content: MImageContent;
  fullImageUrl: string;
  thumbnailUrl: string;
}

interface MImageContent {
  body: string;
  info: ImageInfo;
  msgtype: string;
  url: string;
}

interface MMessage {
  content: MMessageContent;
}

interface MMessageContent {
  body: string;
  format: string;
  formattedBody: string;
  msgtype: string;
}

interface TaskEventContent {
  body: string;
  msgType: 'm.text';
  taskId: string;
}
interface TaskEvent {
  content: TaskEventContent;
  type: `com.bycore.task.${TaskStatus}`;
}

interface MMapPdf {
  content: {
    body: string;
    fileName: string;
    info: {
      mimetype: 'application/pdf';
      size: number;
    };
    msgtype: 'm.file';
    url: string;
  };
  sharedUrl: string;
}

type FeedbackPayload = {
  extChatEvent?: ExtChatEvent | ExtChatEventMention;
  mImage?: MImage;
  mMessage?: MMessage;
  valueInteger?: number;
  taskEvent?: TaskEvent;
  mMapPdf?: MMapPdf;
};

type FeedbackTaskModelDTO = {
  date: string;
  feedbackType: string;
  id: string;
  taskId: string;
  payload: FeedbackPayload;
  projectId: string;
  timeCreated: string;
  workerId: string;
  tags?: string[]; // * Not required for project wide messages.
};

export interface FeedbackProjectModelDTO extends Omit<FeedbackTaskModelDTO, 'taskId'> {
  baseId: string;
  companyId: string;
  id: string;
  projectId: string;
  taskId?: string;
}

export type TaskDetailsModelDTO = {
  activities?: Activity[];
  affectedTaskIds?: string[];
  abbrev: string;
  assignees: TaskAssignees[];
  companyName?: string;
  contributors: {
    id: string;
    taskStatusFrom: string;
    tasksStatusTo: string;
    timeCreated: string;
    workerId: string;
  }[];
  cost?: TaskCost;
  depth: number;
  groups?: TaskGroup[];
  issueTaskIds?: string[];
  statusIssueTaskIdsPairs?: IssueStatusTaskIDsPair[];
  lineage: string[];
  lookaheadColor: string;
  outlineCode: string;
  projectName?: string;
  responsibleParty?: string;
  subtaskCount: number;
  subtasks: TaskDetailsModelDTO[];
  timeRemoved: string;
  timeUnblocked: string;
  projectedLabor: string;
  actualLabor: string;
  csiCode: string;
  phaseCode: string;
  costCode: string;
  costImpact: boolean;
  customCode: string;
  perDateOverride?: TaskPerDateOverrideColor;
  calendarDays?: boolean;
} & TaskModelDTO;

export interface TaskOutlineModelDTO {
  actualEndDate: string;
  actualStartDate: string;
  assigneeCount: number;
  id: string;
  location: string;
  name: string;
  outlineCode: string;
  outlineIsLeaf: boolean;
  outlineSortKey: string;
  projectId: string;
  punchlist: PunchList;
  responsibleParty: string;
  schedEndDate: string;
  schedStartDate: string;
  status: TaskStatus;
  type: string;
  uniqueId: string;
}

type TaskGanttModelDTODates = {
  actual_start: string;
  actual_end: string;
  actual_end_date: string;
  actual_start_date: string;
  end_date: string;
  sched_end_date: string;
  sched_start_date: string;
  start_date: string;
};

export type TaskGanttModelDTO = {
  activities: Activity[];
  $has_child: number;
  abbrev?: string;
  assignee_count: number;
  assignment_count?: number;
  calendar_days: boolean;
  completion_amount: string;
  completion_target: string;
  completion_unit: TaskCompletionUnits;
  cost: TaskCost;
  created_by: string;
  created_using: string;
  date_list: string[];
  depth?: number;
  description: string;
  duration: number;
  est_labor_hours: number;
  has_child?: number; // TODO: clientside, not exist on server
  id: string;
  issue_task_ids: string[];
  status_issue_task_ids_pairs: IssueStatusTaskIDsPair[];
  keywords: string;
  location: string;
  lookahead_color?: string;
  name: string;
  object_type: TaskObjectType;
  object_subtype: TaskObjectSubType;
  open: boolean | number;
  outline_code?: string;
  outline_is_leaf: boolean;
  outline_sort_key: string;
  parent: string;
  progress: string;
  project_id: string;
  punchlist?: PunchList;
  responsible: TaskAssigneesGantt[];
  responsible_org: GanttCompanyOrgs;
  responsible_org_id?: string;
  responsible_party?: string;
  status: TaskStatus;
  status_history: TaskHistoryRawType[];
  subtask_count: number;
  time_created: string;
  time_removed?: string;
  time_unblocked?: string;
  time_updated: string;
  type: string;
  unique_id: string;
  projected_labor: string;
  estimatedLabor: string;
  averageLabor: string;
  actual_labor: string;
  csi_code: string;
  phase_code: string;
  cost_code: string;
  custom_code: string;
  feedback_by_date: FeedbackByDateTaskRaw[];
  feedback_rollup: FeedbackRollup;
  comment_count: number | string;
} & TaskGanttModelDTODates;

export type TaskGanttLastChangedFields = {
  [keys: string]: {
    oldValue?: unknown;
    newValue: unknown;
  };
};

export interface TaskGanttModel extends Omit<TaskModelRawDTO, TaskDateFieldsRaw | 'progress'> {
  lastChangedFields: TaskGanttLastChangedFields;
  meta: {
    loading?: boolean;
    predecessor?: boolean;
    variance?: boolean;
  };
  parent: string;
  index?: number;
  projectId: string;
  actual_start: Date;
  actual_end: Date;
  start_date: Date;
  end_date: Date;
  start_date_real?: Date;
  end_date_real?: Date;
  color?: string;
  taskStatus: string;
  taskDuration: number;
  timeRemoved?: string;
  punchList?: PunchListCountModel;
  datesIsPristine?: boolean;
  visible?: boolean;
  subtask_count?: number;
  has_child?: number;
  $has_child?: number;
  $level?: number;
  open?: number | boolean;
  type?: string;
  taskType?: string;

  // TODO separate DTO and make gantt component generic?
  comment_count?: number | string;
  activities?: Activity[];
  cost_days?: number;
  cost_impact?: boolean;
  cost_tracking_number?: string;
  culpable_org_id?: string;
  culpable_org?: GanttCompanyOrgs;
  impact?: TaskIssueImpact;
  issue_type?: string;
  task_ids?: string[];
  inprogressDate?: Date;
  doneDate?: Date;
  calendar_days: boolean;
  unscheduled?: boolean;
  isPending?: boolean;
}

export type TaskCompletionUnits = '%' | 'LF' | 'CY' | 'EA' | '';

type TaskCreationSource = 'importer' | 'c4' | 'admin' | 'ios' | 'android' | 'web';

export type PunchList = {
  items: {
    itemName: string;
    complete: boolean;
  }[];
};

export type TaskHistoryType = {
  isSharedLogin: boolean;
  guestName: string;
  status?: TaskStatus;
  timeUpdated?: string;
  workerId?: string;
  workerName?: string;
};

export type TaskHistoryRawType = {
  is_shared_login: boolean;
  guest_name: string;
  status?: TaskStatus;
  time_updated?: string;
  worker_id?: string;
  worker_name?: string;
};

interface TaskCost {
  amount: number;
  currency: 'USD' | 'BRL';
}

export type TaskListMinimalModel = {
  id: string;
};

export type ConfigSharedTask = {
  headers: {
    [key: string]: string;
  };
};

export type TaskOutlineBasicModel = {
  id: string;
  key: string;
};

export type TaskMoveRequest = {
  taskId: string;
  parentTaskId?: string;
  ordinal: number;
};

export type TaskCopyRequest = {
  relativeDir: TaskRelativeDirection;
  relativeToId: string;
  taskIds: string[];
};
export enum TaskRelativeDirection {
  Before = 'before',
  After = 'after',
  Into = 'into',
}
export type TaskRelativeMoveRequest = {
  relativeDir: TaskRelativeDirection;
  relativeToId: string;
  taskId?: string;
  taskIds?: string[];
};

export interface PunchListCountModel {
  checked: number;
  total: number;
}

export type GanttCompanyOrgs = {
  company_id?: string;
  group?: {
    adhoc?: boolean;
    direct?: boolean;
    id?: string;
    name?: string;
    notifybot?: boolean;
  };
  group_id?: string;
  id?: string;
  abbrev?: string;
  lookahead_color?: string;
};

export interface GanttLinkModelDTO {
  id: string;
  source: string;
  target: string;
  type: string;
}

// `isPending` is not part of the response returned from the api but is needed in some logic to indicate that a response has been received
export type CreateTaskResponse = TaskModelRawDTO & {affected_task_ids: string[]; isPending: boolean};

export type TasksResponseType =
  | TaskDetailsModelDTO[]
  | TaskModelDTO[]
  | TaskListMinimalModel[]
  | TaskGanttModelDTO[]
  | TaskModelRawDTO[];

export type TaskWithAffectedResponse = {
  task: TaskModelRawDTO;
  affected_tasks: TaskModelRawDTO[];
};

export interface GanttLinkModel extends GanttLinkModelDTO {
  deletedAt?: number;
}

// Gantt not have onAfterRedo value, oldValue type
export type RedoType = {
  type: 'add' | 'remove' | 'delete';
  entity: 'task' | 'link';
  value: unknown | undefined;
  oldValue: unknown | undefined;
};

export type BaselineSnapshot = {
  id?: string;
  name: string;
  timestamp: string;
  createdByName?: string;
  createdBy?: string;
};

export type SnapshotPayload = {
  id: string;
  baselines: BaselineSnapshot[];
};

export type DeleteSnapshotPayload = SnapshotPayload;
export type CreateSnapshotPayload = SnapshotPayload;
export type AddIssueDayPayload = {affectedDate: string; issueDayType: 'move' | 'overlap'; issueIds: string[]};

export type ResponseBaselineTasks = {tasks: BaselineTasks[]};

export type BaselineTasks = {
  id: string;
  baseline_start: string;
  baseline_end: string;
  variance: number;
};

export enum TaskColorMode {
  COMPANY = 'company',
  STATUS = 'status',
  LABOR = 'labor',
}

export enum TaskIssueType {
  DesignEngineering = 'design_engineering',
  DrawingDiscrepancies = 'drawing_discrepancies',
  LaborWorkforce = 'labor_workforce',
  LackofPlanning = 'lack_of_planning',
  Manufacturing = 'manufacturing',
  MaterialEquipment = 'material_equipment',
  Other = 'other',
  Quality = 'quality',
  Safety = 'safety',
  SiteConditionsAccess = 'site_conditions_access',
  TradeCoordination = 'trade_coordination',
  Weather = 'weather',
}

export enum TaskIssueImpact {
  None = 'none',
  Partial = 'partial',
  DayForDay = 'day_for_day',
}
