// eslint-disable-next-line import/no-named-as-default
import Dinero from 'dinero.js';
import {TFunction} from 'i18next';
import React, {FC, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {generatePath, useHistory, useParams} from 'react-router';
import dayjs from 'dayjs';

import {ImportApi} from 'api';
import {Icon, OptionType, Popup} from 'shared/components';
import {TasksViewMode} from 'shared/constants/common';
import {useLocalizedRoutes} from 'shared/constants/routes';
import {deleteNullOrEmptyFields, moveArrayElement} from 'shared/helpers/common';
import {useAnalyticsService, useCompany} from 'shared/hooks';
import {useProject} from 'shared/hooks/useProject';
import {useRootSelector} from 'store';

import {
  TaskImportResult,
  TasksImportMajorError,
  TasksImportMapping,
  TasksImportPreview,
  TasksImportSource,
} from './components';
import {TasksImportProvider, useTasksImportContext} from './TasksImportContext';
import {TaskImportStep} from './utils/constants';
import {filterEmptyTasks, getTasksSortFuncByMapping, isMppFile} from './utils/functions';
import {CurrencyObject, TaskImportField, TaskImportObject, TasksImportConfig} from './utils/types';
import {formatInProjectTz} from 'shared/helpers/dates';

type TasksImportPopupProps = {
  visible: boolean;
  onClose: () => void;
  onFinish: (importedTasks?: TaskImportObject['task'][]) => void;
  defaultProjectId?: string;
  viewMode?: TasksViewMode;
};

const TasksImportPopup: FC<TasksImportPopupProps> = ({visible, onClose, onFinish, defaultProjectId, viewMode}) => {
  const profile = useRootSelector((state) => state.profile.worker);
  const company = useCompany();
  const {projectId} = useParams<{projectId: string}>();
  const {project} = useProject(projectId);
  const history = useHistory();
  const routes = useLocalizedRoutes();

  const [
    {
      currentStep,
      parseResult,
      importErrorCode,
      csv,
      config,
      compareResult,
      showResult,
      asyncUploadId,
      completePercentage,
      file,
    },
    actions,
  ] = useTasksImportContext();

  const {mixpanel} = useAnalyticsService({extraMeta: {projectId, viewMode}});
  const mixpanelEvents = mixpanel.events.tasks.import;
  const {t} = useTranslation('import');

  const fileHeaders = useMemo(
    () =>
      parseResult?.result.headers
        ? parseResult?.result.headers.reduce<OptionType[]>((acc, field) => {
            if (field === 'outline_code') {
              if (isMppFile(file.name)) return acc;
              acc.push({label: t('fields.outline.label', 'Outline Number'), value: field});
              return acc;
            }
            acc.push({label: field, value: field});
            return acc;
          }, [])
        : [],
    [parseResult],
  );

  const getImportFields = (t: TFunction): TaskImportField[] => [
    {
      label: t('import:fields.id.label', 'Unique ID'),
      columnTitle: t('import:fields.id.title', 'ID'),
      key: 'uniqueId',
      required: true,
    },
    {
      label: t('import:fields.name.label', 'Name'),
      columnTitle: t('import:fields.name.title', 'Name'),
      key: 'name',
      required: true,
    },
    {
      label: t('import:fields.start.label', 'Start Date'),
      columnTitle: t('import:fields.start.title', 'Start'),
      key: 'schedStartDate',
      required: true,
      accessor: (value) => {
        if (typeof value === 'string') {
          // Parse the ISO timestamp, then convert to default (project) timezone and format
          return formatInProjectTz(dayjs(value), 'L');
        }
        return value;
      },
    },
    {
      label: t('import:fields.due.label', 'Due Date'),
      columnTitle: t('import:fields.due.title', 'Due'),
      key: 'schedEndDate',
      required: true,
      accessor: (value) => {
        if (typeof value === 'string') {
          return formatInProjectTz(dayjs(value), 'L');
        }
        return value;
      },
    },
    {
      label: t('import:fields.subcontractor.label', 'Subcontractor Group'),
      columnTitle: t('import:fields.subcontractor.title', 'Subcontractor Group'),
      key: 'subcontractor',
    },
    {
      label: t('import:fields.assignees.label', 'Assignees '),
      columnTitle: t('import:fields.assignees.title', 'Assigned to'),
      key: 'assignedNames',
      accessor: (value, data) => data.map((assignee) => assignee.name).join(' / '),
    },
    {
      label: t('import:fields.type.label', 'Type'),
      columnTitle: t('import:fields.type.title', 'Type'),
      key: 'type',
    },
    {
      label: t('import:fields.location.label', 'Location'),
      columnTitle: t('import:fields.location.title', 'Location'),
      key: 'location',
    },
    {
      label: t('import:fields.description.label', 'Description'),
      columnTitle: t('import:fields.description.title', 'Description'),
      key: 'description',
    },
    {
      label: t('import:fields.outline.label', 'Outline Number'),
      columnTitle: t('import:fields.outline.title', 'Outline Number'),
      key: 'outlineCode',
    },
    {
      label: t('import:fields.cost.label', 'Cost'),
      key: 'cost',
      columnTitle: t('import:fields.cost.title', 'Cost'),
      accessor: (value) => {
        return value?.cost
          ? Dinero({
              amount: (value.cost as CurrencyObject)?.amount ?? 0,
              currency: (value.cost as CurrencyObject)?.currency,
            }).toFormat()
          : t('import:fields.cost.not_applicable', 'N/A');
      },
    },
    {
      label: t('import:fields.should.label', 'Should Import?'),
      key: 'shouldImport',
    },
  ];

  const importFields = getImportFields(t);

  const onSubmit = async (asyncUploadId: string, values: TasksImportConfig) => {
    const mapping = {...values.mapping};
    deleteNullOrEmptyFields(mapping);
    await ImportApi.dryRun(values.defaultProject, asyncUploadId, {
      dateFormat: values.dateFormat,
      mapping,
    });
    const dryRunData = await ImportApi.pollDryDunResults(
      values.defaultProject,
      asyncUploadId,
      60,
      actions.setPercentage,
    );
    if (dryRunData.status === 'finished') {
      const preparedTasks = filterEmptyTasks(dryRunData.result.taskResponses);
      preparedTasks.sort(getTasksSortFuncByMapping(mapping));

      actions.setCompareResult({
        ...dryRunData.result,
        taskResponses: preparedTasks,
      });
      actions.setConfig(values);
      actions.setCurrentStep(TaskImportStep.Confirm);
      actions.setPercentage(0);
    }
    if (dryRunData.status === 'failed') {
      if (dryRunData.result.errors) {
        actions.setErrorCode(dryRunData.result.errors[0].code);
      } else if (dryRunData.result.error) {
        actions.setErrorCode('500');
      }
    }
  };

  const getTabs = (t: TFunction) => [
    {id: TaskImportStep.SelectSource, title: t('import:tabs.upload.title', 'Upload File')},
    {id: TaskImportStep.Mapping, title: t('import:tabs.map_file_columns.title', 'Map File Columns')},
    {id: TaskImportStep.Confirm, title: t('import:tabs.confirm.title', 'Confirm List')},
  ];

  const tabs = getTabs(t);

  const previewFields = useMemo(() => {
    if (config) {
      const clearedImportFields = importFields.filter((field) => !!config.mapping[field.key]);
      return config.mapping.outlineCode
        ? moveArrayElement(
            clearedImportFields,
            clearedImportFields.findIndex((el) => el.key === 'outlineCode'),
            1,
          )
        : clearedImportFields;
    }
    return importFields;
  }, [config]);

  const startImport = async () => {
    const mapping = {...config.mapping};
    deleteNullOrEmptyFields(mapping);
    await ImportApi.commit(config.defaultProject, asyncUploadId, {
      mapping: {...mapping, assignedNames: mapping.assignedNames, archiveTask: mapping.archived},
      dateFormat: config.dateFormat,
    });
    const commitData = await ImportApi.pollCommitResults(
      config.defaultProject,
      asyncUploadId,
      4_800, // 80 minutes for large imports 😢
      actions.setPercentage,
    );

    if (commitData.status === 'finished') {
      const preparedTasks = commitData.result.taskResponses.filter(
        (taskResponse) => !!taskResponse.task && taskResponse.status !== 'failed',
      );
      preparedTasks.sort(getTasksSortFuncByMapping(mapping));

      // TODO: finish up with csv data passing to result
      actions.setScv(commitData.result.export);
      actions.setCompareResult({
        ...commitData.result,
        taskResponses: preparedTasks,
      });
      actions.setShowResult(true);
    } else if (commitData.status === 'failed') {
      if (commitData.result.errors) {
        actions.setErrorCode(commitData.result.errors[0].code);
      } else if (commitData.result.error) {
        actions.setErrorCode('500');
      }
    }
  };

  function finishAndRedirect() {
    onFinish(compareResult.taskResponses.map((res) => res.task));
    history.push({
      pathname: generatePath(routes.tasks, {projectId: config.defaultProject}),
    });
  }

  const handleClose = () => {
    if (showResult) {
      finishAndRedirect();
    } else {
      onClose();
    }
  };

  function getStepContent() {
    switch (currentStep) {
      case TaskImportStep.SelectSource:
        return <TasksImportSource viewMode={viewMode} mixpanelEvents={mixpanelEvents} />;
      case TaskImportStep.Mapping:
        return (
          <TasksImportMapping
            fields={importFields}
            parseResult={parseResult}
            onSubmit={onSubmit}
            fileHeaders={fileHeaders}
            importPercentage={completePercentage}
            defaultProjectId={defaultProjectId}
            mixpanelEvents={mixpanelEvents}
          />
        );
      case TaskImportStep.Confirm:
        return (
          <TasksImportPreview
            result={compareResult}
            fields={previewFields}
            onBack={() => mixpanel.trackWithAction(() => actions.setCurrentStep(0), mixpanelEvents.backToMapping)}
            onSubmit={() => mixpanel.trackWithAction(startImport, mixpanelEvents.finishImport)}
            onClose={onFinish}
            importPercentage={completePercentage}
          />
        );
      default:
        return null;
    }
  }

  function getPopupBody() {
    if (importErrorCode)
      return (
        <TasksImportMajorError
          tryAgain={() => {
            actions.setErrorCode('');
            actions.setCurrentStep(TaskImportStep.SelectSource);
          }}
          errorCode={importErrorCode}
          companyName={company.companyName}
          profile={profile}
          textToShow={t('errors.messages.default', 'There was an error importing your activities')}
        />
      );
    if (showResult) {
      return (
        <TaskImportResult
          onFinish={finishAndRedirect}
          totals={{
            newTasks: compareResult.addedCount,
            edited: compareResult.updatedCount,
            error: compareResult.taskResponses.filter((taskResponse) => taskResponse.errors.length).length,
            unChanged: compareResult.unchangedCount,
          }}
          csv={csv}
        />
      );
    }
    return getStepContent();
  }

  return (
    <Popup visible={visible} onClose={handleClose}>
      <Popup.Header>
        <div className="tabs-nav popup__nav">
          {tabs.map((tab, index) => (
            <button
              key={tab.id}
              className={`tabs-nav__button ${currentStep === tab.id ? 'is-active' : ''}`}
              type="button"
            >
              {index + 1}. {tab.title}
            </button>
          ))}
        </div>
      </Popup.Header>
      {getPopupBody()}
      <button
        className="ctrl-btn-clear popup__button-close"
        onClick={() =>
          mixpanel.trackWithAction(
            handleClose,
            mixpanelEvents.exitCompleteScreen,
            {'Project Name:': project.name},
            showResult,
          )
        }
        type="button"
      >
        <span className="ctrl-btn-clear__text">{t('errors.buttons.close', 'close')}</span>
        <Icon colorFill className="ctrl-btn-clear__icon" size={24} name="clear" />
      </button>
    </Popup>
  );
};

function TaskImportPopupWrapper(props: TasksImportPopupProps) {
  return (
    <TasksImportProvider>
      <TasksImportPopup {...props} />
    </TasksImportProvider>
  );
}
export default TaskImportPopupWrapper;
