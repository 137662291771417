import dayjs from 'dayjs';
import {Fragment} from 'react';
import {Trans, useTranslation} from 'react-i18next';
import {useHistory} from 'react-router';

import {useFilterContext} from 'modules/Tasks/components/Filters';
import {ImageGallery} from 'modules/Tasks/components/SidebarPanel/IssuePanel/components/ImageGallery/ImageGallery';
import {Button, Icon} from 'shared/components';
import {Pill} from 'shared/components/Pill/Pill';
import {IconsMap} from 'shared/constants/icons';
import {useAnalyticsService} from 'shared/hooks';
import {TaskActiveTab} from 'shared/models/task';
import {FeedbackGrouped} from 'shared/models/task/comments';

import {redirectToGanttView} from '../utils/handler';

import {DailyCardSeparator} from './DailyCardSeparator';

export function DailiesCardComments({taskId, comments}: {taskId: string; comments: FeedbackGrouped[]}) {
  const {t} = useTranslation('dailies');
  const history = useHistory();
  const {queryParams} = useFilterContext();
  const {
    mixpanel: {events, trackWithAction},
  } = useAnalyticsService();
  if (!comments?.length) {
    return null;
  }

  return (
    <>
      <section className="dailies-card-comments">
        <div>
          <p>
            {t('dailies_report.comments.comments.heading')} {comments?.length}
          </p>
        </div>
        <div className="dailies-card-comments--add-comment-action">
          <Button
            buttonSize="xs"
            className="add_activity_btn"
            colorStyle="light"
            rightAddon={<Icon className="add_activity_btn-icon" name={IconsMap['add-plus']} size={12} />}
            suffix="add_activity_btn"
            onClick={() => {
              trackWithAction(
                () =>
                  redirectToGanttView({
                    taskId,
                    activeTab: TaskActiveTab.chat,
                    history,
                    schedEndFirst: queryParams.schedEndFirst,
                    newIssueFocus: true,
                  }),
                events.dailies.dailiesActivityReportAddComment,
              );
            }}
          >
            {t('dailies_report.comments.comment')}
          </Button>
        </div>
      </section>
      <DailyCardSeparator className="force-display" />

      {comments?.map((comment) => {
        const attachments = comment?.feedbackTypes?.mImageComments?.map((comment) => ({
          url: comment.imageUrl,
        }));

        return (
          <Fragment key={comment.key}>
            <aside className="dailies-card-comments">
              <div>
                {comment?.feedbackTypes?.mMessageComments?.map((comment) => {
                  const {workerFullName, timeCreated} = comment;
                  const formattedTimeCreated = dayjs(timeCreated).format('HH:mm');
                  return (
                    <div key={comment.id}>
                      <div className="dailies-card-comments-comment-header">
                        <span className="dailies-card-comments-comment-header--heading">
                          {t('dailies_report.comments.comment')}
                        </span>
                        <Icon name={IconsMap['comment-vs']} height={12} width={12} />
                      </div>

                      <div>
                        <p>{comment.body}</p>
                      </div>

                      <div className="dailies-card-comments-issue--submitter">
                        <p>
                          <Trans
                            i18nKey="dailies:dailies_report.comments.comments.submitter"
                            components={{
                              span: <span className="daily-report-card__report-metrics-submitter-name" />,
                            }}
                            values={{formattedTimeCreated, workerFullName}}
                          />
                        </p>

                        {comment.tags?.map((tagName, index) => {
                          return (
                            <Pill
                              color="red"
                              size="xs"
                              key={tagName + index}
                              icon={<Icon name={IconsMap.flag} height={12} width={12} />}
                            >
                              {t('dailies_report.comments.potential_issue')}
                            </Pill>
                          );
                        })}
                      </div>
                    </div>
                  );
                })}
                <ImageGallery attachments={attachments} />
              </div>

              <div className="dailies-card-comments-issue--add-issue-action">
                <Button
                  buttonSize="xs"
                  className="add_activity_btn"
                  colorStyle="light"
                  rightAddon={<Icon className="add_activity_btn-icon" name={IconsMap['add-plus']} size={12} />}
                  suffix="add_activity_btn"
                  onClick={() => {
                    trackWithAction(
                      () =>
                        redirectToGanttView({
                          taskId,
                          activeTab: TaskActiveTab.info,
                          history,
                          newIssueFocus: true,
                        }),
                      events.dailies.dailiesActivityReportCreateIssue,
                    );
                  }}
                >
                  {t('dailies_report.comments.issue')}
                </Button>
              </div>
            </aside>
            <DailyCardSeparator />
          </Fragment>
        );
      })}
    </>
  );
}
