import {GanttStatic} from 'dhtmlx-gantt';
import {useEffect, useRef} from 'react';
import {useLocation} from 'react-router';

import {TasksLocationState} from 'modules/Tasks/types/location';
import {IOC_TYPES} from 'shared/models/ioc';
import {TaskActiveTab} from 'shared/models/task';
import {useInjectStore} from 'shared/providers/injection';
import {UIStoreType} from 'shared/stores/UIStore';

type MentionsRef = {
  taskId: string;
  eventId: string;
} | null;

export const useNavigateToMentions = (
  gantt: GanttStatic,
  navigateToCommentTab: (taskId: string, tab: TaskActiveTab, options: {eventId: string}) => void,
) => {
  const mentionsRef = useRef<MentionsRef>(null);
  const location = useLocation<TasksLocationState>();
  const uiStore = useInjectStore<UIStoreType>(IOC_TYPES.UIStore);

  useEffect(() => {
    const eventId = location.state?.eventId;
    const taskId = location.state?.taskId;
    if (eventId && taskId) {
      mentionsRef.current = {taskId, eventId};
    }
    if (
      mentionsRef.current?.eventId &&
      mentionsRef.current?.taskId &&
      gantt.isTaskExists(mentionsRef.current?.taskId) &&
      !uiStore.isLoading
    ) {
      navigateToCommentTab(mentionsRef.current?.taskId, TaskActiveTab.chat, {eventId: mentionsRef.current?.eventId});
      mentionsRef.current = null;
    }
  }, [gantt, location.state?.eventId, location.state?.taskId, navigateToCommentTab, uiStore.isLoading]);
};
