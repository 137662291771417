import {Formik, FormikProps} from 'formik';
import React, {FC, useEffect, useRef} from 'react';
import {useTranslation} from 'react-i18next';
import {useQuery} from 'react-query';

import {CompanyApi} from 'api';
import styles from 'modules/Tasks/components/ActionsBar/components/FilterDropdown/FilterDropdown.module.scss';
import SearchField from 'modules/Tasks/components/ActionsBar/components/SearchField/SearchField';
import {FormikChangeWatcher, Icon} from 'shared/components';
import {Autocomplete, CtrlBtnOption, Dropdown} from 'shared/components/CoreNewUI';
import {QUERY_CACHE_KEYS} from 'shared/constants/queryCache';
import {useAnalyticsService, useCompany} from 'shared/hooks';
import {useDebounce} from 'shared/hooks/core';
import {useRootSelector} from 'store';
import {isCompanyAdminSelector} from 'store/profile';

import {getDefaultValues, ProjectFilterParams} from './helpers';

type ProjectSearchProps = {
  updateSearchInput: (nameProject: string) => void;
  updateFilterParams: (value: ProjectFilterParams) => void;
  searchInput: string;
  isActiveFilter: boolean;
};

const ProjectsFilterAndSearch: FC<ProjectSearchProps> = ({
  updateSearchInput,
  updateFilterParams,
  searchInput,
  isActiveFilter,
}) => {
  const {
    mixpanel: {
      events: {projects: mixpanelEvents},
      ...mixpanel
    },
  } = useAnalyticsService();
  const {t} = useTranslation(['filters']);
  const company = useCompany();
  const touched = useRef(false);
  const formik = useRef<FormikProps<ProjectFilterParams>>();
  const isCompanyAdmin = useRootSelector(isCompanyAdminSelector);

  const {data: orgsName, isLoading: isLoadingOrgs} = useQuery(
    QUERY_CACHE_KEYS.companySubcontractors(company.id),
    () => {
      return CompanyApi.getCompanyOrgs(company.id);
    },
    {
      select: (orgs) =>
        orgs.map((org) => ({
          label: org.group.name,
          value: org.id,
        })),
      enabled: !!company?.id && isCompanyAdmin,
      refetchOnWindowFocus: false,
      refetchOnMount: true,
    },
  );

  const debouncedOnSearchChange = useDebounce((searchText: string) => {
    if (!touched.current) {
      mixpanel.track(mixpanelEvents.buttons.searchProject);
      touched.current = true;
    }
    updateSearchInput(searchText);
  }, 500);

  useEffect(() => {
    debouncedOnSearchChange(searchInput);
  }, [searchInput, debouncedOnSearchChange]);

  return (
    <form className="form-default">
      <div className="form-default__container">
        <div className="form-default__grid">
          <div className="form-default__item form-default__item--full">
            <Dropdown
              viewportPosition="right"
              toggleElement={<SearchField active={isActiveFilter} name="searchProject" onChange={updateSearchInput} />}
              toggleElementId="toolbarFiltersToggle"
              header={{
                title: t('filters:dropdown.header', 'Filters'),
                button: {
                  title: t('filters:dropdown.reset', 'Reset all'),
                  onClick: () => formik.current.resetForm({values: getDefaultValues()}),
                },
              }}
            >
              <Formik<ProjectFilterParams> initialValues={getDefaultValues()} innerRef={formik} onSubmit={() => null}>
                {({setFieldValue, values}) => (
                  <>
                    <FormikChangeWatcher debounce={200} onChange={updateFilterParams} />
                    <CtrlBtnOption
                      openOnHover
                      className={styles.filterDropdown__buttonOption}
                      title={t('filters:tasks.options.owner', 'Owner')}
                      countSelected={values.ownerCompanyGroupMappingId.length ? 1 : 0}
                      icon={<Icon colorFill name="subcontractor" />}
                      iconRight={values.ownerCompanyGroupMappingId.length ? <Icon name="clear" colorFill /> : null}
                      onClick={() => setFieldValue('ownerCompanyGroupMappingId', [])}
                      nestedContentPlacement="left"
                      nested={
                        <Autocomplete
                          items={orgsName}
                          name="ownerCompanyGroupMappingId"
                          onSelect={(name, value) => setFieldValue(name, value)}
                          selected={values.ownerCompanyGroupMappingId}
                          disabled={isLoadingOrgs}
                        />
                      }
                    />
                    <CtrlBtnOption
                      openOnHover
                      className={styles.filterDropdown__buttonOption}
                      title={t('filters:tasks.options.engineer', 'Engineer')}
                      countSelected={values.engineerCompanyGroupMappingId.length ? 1 : 0}
                      icon={<Icon colorFill name="subcontractor" />}
                      iconRight={values.engineerCompanyGroupMappingId.length ? <Icon name="clear" colorFill /> : null}
                      onClick={() => setFieldValue('engineerCompanyGroupMappingId', [])}
                      nestedContentPlacement="left"
                      nested={
                        <Autocomplete
                          items={orgsName}
                          name="engineerCompanyGroupMappingId"
                          onSelect={(name, value) => setFieldValue(name, value)}
                          selected={values.engineerCompanyGroupMappingId}
                          disabled={isLoadingOrgs}
                        />
                      }
                    />
                    <CtrlBtnOption
                      openOnHover
                      className={styles.filterDropdown__buttonOption}
                      title={t('filters:tasks.options.architect', 'Architect')}
                      countSelected={values.architectCompanyGroupMappingId.length ? 1 : 0}
                      icon={<Icon colorFill name="subcontractor" />}
                      iconRight={values.architectCompanyGroupMappingId.length ? <Icon name="clear" colorFill /> : null}
                      onClick={() => setFieldValue('architectCompanyGroupMappingId', [])}
                      nestedContentPlacement="left"
                      nested={
                        <Autocomplete
                          items={orgsName}
                          name="architectCompanyGroupMappingId"
                          onSelect={(name, value) => setFieldValue(name, value)}
                          selected={values.architectCompanyGroupMappingId}
                          disabled={isLoadingOrgs}
                        />
                      }
                    />
                    <CtrlBtnOption
                      openOnHover
                      className={styles.filterDropdown__buttonOption}
                      title={t('filters:tasks.options.subcontractor', 'Subcontractor')}
                      countSelected={values.subcontractorCompanyGroupMappingId.length ? 1 : 0}
                      icon={<Icon colorFill name="subcontractor" />}
                      iconRight={
                        values.subcontractorCompanyGroupMappingId.length ? <Icon name="clear" colorFill /> : null
                      }
                      onClick={() => setFieldValue('subcontractorCompanyGroupMappingId', [])}
                      nestedContentPlacement="left"
                      nested={
                        <Autocomplete
                          items={orgsName}
                          name="subcontractorCompanyGroupMappingId"
                          onSelect={(name, value) => setFieldValue(name, value)}
                          selected={values.subcontractorCompanyGroupMappingId}
                          disabled={isLoadingOrgs}
                        />
                      }
                    />
                  </>
                )}
              </Formik>
            </Dropdown>
          </div>
        </div>
      </div>
    </form>
  );
};

export default ProjectsFilterAndSearch;
